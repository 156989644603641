<script setup>
const props = defineProps(["singleBible", "items"]);

function handleChangedChapter({ chapterNum }) {
  useEvent("singlebible.selectChapter", {
    singleBible: props.singleBible,
    bookCode: props.singleBible?.location.value?.bookModel?.bookCode,
    chapterNum,
  });
}
</script>

<template>
  <v-menu origins="top left">
    <template #activator="{ on }">
      <!-- <label class="menu-label">{{ transl("Chapter") }}</label> -->
      <v-btn @click="on()" class="select-button">
        {{ singleBible?.location.value?.chapterNum }}
        <img class="chevron" src="/icons/chevron-down.svg" alt="" />
      </v-btn>
    </template>

    <template #default="{ close }">
      <VChaptersList
        :items="singleBible?.location.value?.bookModel?.bookChapters"
        :singleBible="singleBible"
        @change="
          handleChangedChapter($event);
          close();
        "
        class="mb-4 ml-4"
      ></VChaptersList>
    </template>
  </v-menu>
</template>

<style scoped>
.chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 7px;
  top: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.select-button {
  border-radius: 6px;
  background-color: var(--root-white);
  width: 100%;
  color: black;
  padding: 9px 4px 8px 9px;
  justify-content: flex-start;
  box-shadow: 0px 4px 11px 0px #e1e5f1;
  height: 40px;
  align-items: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.back-button {
  display: inline-flex;
}
.icon {
  min-height: 24px;
  min-width: 24px;
  align-items: flex-start;
}
.tabs {
  display: flex;
  transition: all 0.2s ease;
  position: relative;
  /* overflow: hidden; */
}

.tabs-slider {
  display: flex;
  /* position: absolute; */
  /* left: 0; */
  /* top: 0; */
  transition: all 0.2s ease;
}

.books-el {
  transition: all 0.2s ease;
  transform: translate3d(-50%, 0, 0);
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.books-el.active {
  transform: translate3d(0, 0, 0);
  height: unset;
  width: unset;
  opacity: 1;
  visibility: visible;
}

.chapters-el {
  transition: all 0.2s ease;
  transform: translate3d(60%, 0, 0);
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
}

.chapters-el.active {
  transform: translate3d(0, 0, 0);
  height: unset;
  width: unset;
  opacity: 1;
  visibility: visible;
}

.menu-header {
  line-height: 50px;
}
</style>
